import { default as _91_46_46_46catchAll_93FeJEJndXm7Meta } from "/codebuild/output/src2610789722/src/storefront/pages/[...catchAll].vue?macro=true";
import { default as about_45usqC1OywTEuTMeta } from "/codebuild/output/src2610789722/src/storefront/pages/about-us.vue?macro=true";
import { default as barion_45startqqKitbjbjbMeta } from "/codebuild/output/src2610789722/src/storefront/pages/barion-start.vue?macro=true";
import { default as bestsellers_45of_45the_45monthxM7Flb4HG6Meta } from "/codebuild/output/src2610789722/src/storefront/pages/bestsellers-of-the-month.vue?macro=true";
import { default as book_45clubjcVUhukmccMeta } from "/codebuild/output/src2610789722/src/storefront/pages/book-club.vue?macro=true";
import { default as _91slug_93PaRtPpS5lHMeta } from "/codebuild/output/src2610789722/src/storefront/pages/book/[slug].vue?macro=true";
import { default as _91slug_93BUWE1XKdjcMeta } from "/codebuild/output/src2610789722/src/storefront/pages/books-by-author/[slug].vue?macro=true";
import { default as indexUCBdIPsQyhMeta } from "/codebuild/output/src2610789722/src/storefront/pages/books/[category]/[subcategory]/index.vue?macro=true";
import { default as indexjAubDnexExMeta } from "/codebuild/output/src2610789722/src/storefront/pages/books/[category]/index.vue?macro=true";
import { default as indexPHv4Hjtq6zMeta } from "/codebuild/output/src2610789722/src/storefront/pages/books/index.vue?macro=true";
import { default as indexogWIW8tFxgMeta } from "/codebuild/output/src2610789722/src/storefront/pages/checkout/index.vue?macro=true";
import { default as thankyouECy2UwMRHXMeta } from "/codebuild/output/src2610789722/src/storefront/pages/checkout/thankyou.vue?macro=true";
import { default as contact_45usozPMpJLVxsMeta } from "/codebuild/output/src2610789722/src/storefront/pages/contact-us.vue?macro=true";
import { default as cookie1WagqcjOoTMeta } from "/codebuild/output/src2610789722/src/storefront/pages/cookie.vue?macro=true";
import { default as indexGKuMafiWAiMeta } from "/codebuild/output/src2610789722/src/storefront/pages/index.vue?macro=true";
import { default as login4CluYYKcSqMeta } from "/codebuild/output/src2610789722/src/storefront/pages/login.vue?macro=true";
import { default as _91slug_93cWNovigNQeMeta } from "/codebuild/output/src2610789722/src/storefront/pages/menu-navigation/[slug].vue?macro=true";
import { default as my_45carth0xukrK7e7Meta } from "/codebuild/output/src2610789722/src/storefront/pages/my-cart.vue?macro=true";
import { default as _91slug_93MS7yVpWJASMeta } from "/codebuild/output/src2610789722/src/storefront/pages/my-orders/[slug].vue?macro=true";
import { default as indexybotMTwgBJMeta } from "/codebuild/output/src2610789722/src/storefront/pages/my-orders/index.vue?macro=true";
import { default as newly_45publishedXMaBO5D6BHMeta } from "/codebuild/output/src2610789722/src/storefront/pages/newly-published.vue?macro=true";
import { default as newspaperskoCs2d0M1rMeta } from "/codebuild/output/src2610789722/src/storefront/pages/newspapers.vue?macro=true";
import { default as on_45saleyeJldrXgxCMeta } from "/codebuild/output/src2610789722/src/storefront/pages/on-sale.vue?macro=true";
import { default as _91slug_93z9xHftKtWqMeta } from "/codebuild/output/src2610789722/src/storefront/pages/other-language/[slug].vue?macro=true";
import { default as indexcORgAT27V7Meta } from "/codebuild/output/src2610789722/src/storefront/pages/other-languages/[category]/[subcategory]/index.vue?macro=true";
import { default as indexZuChVBLVEQMeta } from "/codebuild/output/src2610789722/src/storefront/pages/other-languages/[category]/index.vue?macro=true";
import { default as indexcxeEatay8GMeta } from "/codebuild/output/src2610789722/src/storefront/pages/other-languages/index.vue?macro=true";
import { default as payment_45resultveWdwLTaScMeta } from "/codebuild/output/src2610789722/src/storefront/pages/payment-result.vue?macro=true";
import { default as paypal_45startS4Es2cSt6BMeta } from "/codebuild/output/src2610789722/src/storefront/pages/paypal-start.vue?macro=true";
import { default as privacy_45policyvdDCHPNrX3Meta } from "/codebuild/output/src2610789722/src/storefront/pages/privacy-policy.vue?macro=true";
import { default as _91_91subcategory_93_93tN2JGx1hgJMeta } from "/codebuild/output/src2610789722/src/storefront/pages/products/[[category]]/[[subcategory]].vue?macro=true";
import { default as purchase_45and_45shipping_45conditionsEOTQSkgWncMeta } from "/codebuild/output/src2610789722/src/storefront/pages/purchase-and-shipping-conditions.vue?macro=true";
import { default as searchgpff56xB8HMeta } from "/codebuild/output/src2610789722/src/storefront/pages/search.vue?macro=true";
import { default as staff_45recommendationsFd2pBindM0Meta } from "/codebuild/output/src2610789722/src/storefront/pages/staff-recommendations.vue?macro=true";
import { default as _91slug_938BQHjcvRCIMeta } from "/codebuild/output/src2610789722/src/storefront/pages/stationary-and-gift/[slug].vue?macro=true";
import { default as index8AwBh7DW3EMeta } from "/codebuild/output/src2610789722/src/storefront/pages/stationary-and-gifts/[category]/[subcategory]/index.vue?macro=true";
import { default as indexmioAa1aoWAMeta } from "/codebuild/output/src2610789722/src/storefront/pages/stationary-and-gifts/[category]/index.vue?macro=true";
import { default as indexLvq5jWGXxEMeta } from "/codebuild/output/src2610789722/src/storefront/pages/stationary-and-gifts/index.vue?macro=true";
import { default as terms_45and_45conditionsqjLZdo9mIyMeta } from "/codebuild/output/src2610789722/src/storefront/pages/terms-and-conditions.vue?macro=true";
import { default as upcoming_45releaseskTRZOFZjWCMeta } from "/codebuild/output/src2610789722/src/storefront/pages/upcoming-releases.vue?macro=true";
export default [
  {
    name: "catchAll___hu",
    path: "/hu/:catchAll(.*)*",
    meta: _91_46_46_46catchAll_93FeJEJndXm7Meta || {},
    component: () => import("/codebuild/output/src2610789722/src/storefront/pages/[...catchAll].vue").then(m => m.default || m)
  },
  {
    name: "catchAll___en",
    path: "/:catchAll(.*)*",
    meta: _91_46_46_46catchAll_93FeJEJndXm7Meta || {},
    component: () => import("/codebuild/output/src2610789722/src/storefront/pages/[...catchAll].vue").then(m => m.default || m)
  },
  {
    name: "about-us___hu",
    path: "/hu/rolunk",
    component: () => import("/codebuild/output/src2610789722/src/storefront/pages/about-us.vue").then(m => m.default || m)
  },
  {
    name: "about-us___en",
    path: "/about-us",
    component: () => import("/codebuild/output/src2610789722/src/storefront/pages/about-us.vue").then(m => m.default || m)
  },
  {
    name: "barion-start___hu",
    path: "/hu/barion-fizetes",
    meta: barion_45startqqKitbjbjbMeta || {},
    component: () => import("/codebuild/output/src2610789722/src/storefront/pages/barion-start.vue").then(m => m.default || m)
  },
  {
    name: "barion-start___en",
    path: "/barion-start",
    meta: barion_45startqqKitbjbjbMeta || {},
    component: () => import("/codebuild/output/src2610789722/src/storefront/pages/barion-start.vue").then(m => m.default || m)
  },
  {
    name: "bestsellers-of-the-month___hu",
    path: "/hu/a-honap-sikerkonyvei",
    meta: bestsellers_45of_45the_45monthxM7Flb4HG6Meta || {},
    component: () => import("/codebuild/output/src2610789722/src/storefront/pages/bestsellers-of-the-month.vue").then(m => m.default || m)
  },
  {
    name: "bestsellers-of-the-month___en",
    path: "/bestsellers-of-the-month",
    meta: bestsellers_45of_45the_45monthxM7Flb4HG6Meta || {},
    component: () => import("/codebuild/output/src2610789722/src/storefront/pages/bestsellers-of-the-month.vue").then(m => m.default || m)
  },
  {
    name: "book-club___hu",
    path: "/hu/konyvklub",
    component: () => import("/codebuild/output/src2610789722/src/storefront/pages/book-club.vue").then(m => m.default || m)
  },
  {
    name: "book-club___en",
    path: "/book-club",
    component: () => import("/codebuild/output/src2610789722/src/storefront/pages/book-club.vue").then(m => m.default || m)
  },
  {
    name: "book-slug___hu",
    path: "/hu/konyv/:slug()",
    meta: _91slug_93PaRtPpS5lHMeta || {},
    component: () => import("/codebuild/output/src2610789722/src/storefront/pages/book/[slug].vue").then(m => m.default || m)
  },
  {
    name: "book-slug___en",
    path: "/book/:slug()",
    meta: _91slug_93PaRtPpS5lHMeta || {},
    component: () => import("/codebuild/output/src2610789722/src/storefront/pages/book/[slug].vue").then(m => m.default || m)
  },
  {
    name: "books-by-author-slug___hu",
    path: "/hu/szerzo-muvei/:slug()",
    meta: _91slug_93BUWE1XKdjcMeta || {},
    component: () => import("/codebuild/output/src2610789722/src/storefront/pages/books-by-author/[slug].vue").then(m => m.default || m)
  },
  {
    name: "books-by-author-slug___en",
    path: "/books-by-author/:slug()",
    meta: _91slug_93BUWE1XKdjcMeta || {},
    component: () => import("/codebuild/output/src2610789722/src/storefront/pages/books-by-author/[slug].vue").then(m => m.default || m)
  },
  {
    name: "books-category-subcategory___hu",
    path: "/hu/konyvek/:category()/:subcategory()",
    meta: indexUCBdIPsQyhMeta || {},
    component: () => import("/codebuild/output/src2610789722/src/storefront/pages/books/[category]/[subcategory]/index.vue").then(m => m.default || m)
  },
  {
    name: "books-category-subcategory___en",
    path: "/books/:category()/:subcategory()",
    meta: indexUCBdIPsQyhMeta || {},
    component: () => import("/codebuild/output/src2610789722/src/storefront/pages/books/[category]/[subcategory]/index.vue").then(m => m.default || m)
  },
  {
    name: "books-category___hu",
    path: "/hu/konyvek/:category()",
    meta: indexjAubDnexExMeta || {},
    component: () => import("/codebuild/output/src2610789722/src/storefront/pages/books/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: "books-category___en",
    path: "/books/:category()",
    meta: indexjAubDnexExMeta || {},
    component: () => import("/codebuild/output/src2610789722/src/storefront/pages/books/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: "books___hu",
    path: "/hu/konyvek",
    meta: indexPHv4Hjtq6zMeta || {},
    component: () => import("/codebuild/output/src2610789722/src/storefront/pages/books/index.vue").then(m => m.default || m)
  },
  {
    name: "books___en",
    path: "/books",
    meta: indexPHv4Hjtq6zMeta || {},
    component: () => import("/codebuild/output/src2610789722/src/storefront/pages/books/index.vue").then(m => m.default || m)
  },
  {
    name: "checkout___hu",
    path: "/hu/megrendeles",
    meta: indexogWIW8tFxgMeta || {},
    component: () => import("/codebuild/output/src2610789722/src/storefront/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: "checkout___en",
    path: "/checkout",
    meta: indexogWIW8tFxgMeta || {},
    component: () => import("/codebuild/output/src2610789722/src/storefront/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: "checkout-thankyou___hu",
    path: "/hu/megrendeles-koszonjuk",
    meta: thankyouECy2UwMRHXMeta || {},
    component: () => import("/codebuild/output/src2610789722/src/storefront/pages/checkout/thankyou.vue").then(m => m.default || m)
  },
  {
    name: "checkout-thankyou___en",
    path: "/checkout-thankyou",
    meta: thankyouECy2UwMRHXMeta || {},
    component: () => import("/codebuild/output/src2610789722/src/storefront/pages/checkout/thankyou.vue").then(m => m.default || m)
  },
  {
    name: "contact-us___hu",
    path: "/hu/kapcsolat",
    component: () => import("/codebuild/output/src2610789722/src/storefront/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: "contact-us___en",
    path: "/contact-us",
    component: () => import("/codebuild/output/src2610789722/src/storefront/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: "cookie___hu",
    path: "/hu/suti",
    component: () => import("/codebuild/output/src2610789722/src/storefront/pages/cookie.vue").then(m => m.default || m)
  },
  {
    name: "cookie___en",
    path: "/cookie",
    component: () => import("/codebuild/output/src2610789722/src/storefront/pages/cookie.vue").then(m => m.default || m)
  },
  {
    name: "index___hu",
    path: "/hu",
    meta: indexGKuMafiWAiMeta || {},
    component: () => import("/codebuild/output/src2610789722/src/storefront/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/",
    meta: indexGKuMafiWAiMeta || {},
    component: () => import("/codebuild/output/src2610789722/src/storefront/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login___hu",
    path: "/hu/bejelentkezes",
    meta: login4CluYYKcSqMeta || {},
    component: () => import("/codebuild/output/src2610789722/src/storefront/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "login___en",
    path: "/login",
    meta: login4CluYYKcSqMeta || {},
    component: () => import("/codebuild/output/src2610789722/src/storefront/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "menu-navigation-slug___hu",
    path: "/hu/menu-navigacio/:slug()",
    meta: _91slug_93cWNovigNQeMeta || {},
    component: () => import("/codebuild/output/src2610789722/src/storefront/pages/menu-navigation/[slug].vue").then(m => m.default || m)
  },
  {
    name: "menu-navigation-slug___en",
    path: "/menu-navigation/:slug()",
    meta: _91slug_93cWNovigNQeMeta || {},
    component: () => import("/codebuild/output/src2610789722/src/storefront/pages/menu-navigation/[slug].vue").then(m => m.default || m)
  },
  {
    name: "my-cart___hu",
    path: "/hu/kosaram",
    meta: my_45carth0xukrK7e7Meta || {},
    component: () => import("/codebuild/output/src2610789722/src/storefront/pages/my-cart.vue").then(m => m.default || m)
  },
  {
    name: "my-cart___en",
    path: "/my-cart",
    meta: my_45carth0xukrK7e7Meta || {},
    component: () => import("/codebuild/output/src2610789722/src/storefront/pages/my-cart.vue").then(m => m.default || m)
  },
  {
    name: "my-orders-slug___hu",
    path: "/hu/rendeleseim/:slug()",
    meta: _91slug_93MS7yVpWJASMeta || {},
    component: () => import("/codebuild/output/src2610789722/src/storefront/pages/my-orders/[slug].vue").then(m => m.default || m)
  },
  {
    name: "my-orders-slug___en",
    path: "/my-orders/:slug()",
    meta: _91slug_93MS7yVpWJASMeta || {},
    component: () => import("/codebuild/output/src2610789722/src/storefront/pages/my-orders/[slug].vue").then(m => m.default || m)
  },
  {
    name: "my-orders___hu",
    path: "/hu/rendeleseim",
    meta: indexybotMTwgBJMeta || {},
    component: () => import("/codebuild/output/src2610789722/src/storefront/pages/my-orders/index.vue").then(m => m.default || m)
  },
  {
    name: "my-orders___en",
    path: "/my-orders",
    meta: indexybotMTwgBJMeta || {},
    component: () => import("/codebuild/output/src2610789722/src/storefront/pages/my-orders/index.vue").then(m => m.default || m)
  },
  {
    name: "newly-published___hu",
    path: "/hu/ujdonsagok",
    meta: newly_45publishedXMaBO5D6BHMeta || {},
    component: () => import("/codebuild/output/src2610789722/src/storefront/pages/newly-published.vue").then(m => m.default || m)
  },
  {
    name: "newly-published___en",
    path: "/newly-published",
    meta: newly_45publishedXMaBO5D6BHMeta || {},
    component: () => import("/codebuild/output/src2610789722/src/storefront/pages/newly-published.vue").then(m => m.default || m)
  },
  {
    name: "newspapers___hu",
    path: "/hu/ujsagok-es-magazinok",
    component: () => import("/codebuild/output/src2610789722/src/storefront/pages/newspapers.vue").then(m => m.default || m)
  },
  {
    name: "newspapers___en",
    path: "/newspapers-and-magazines",
    component: () => import("/codebuild/output/src2610789722/src/storefront/pages/newspapers.vue").then(m => m.default || m)
  },
  {
    name: "on-sale___hu",
    path: "/hu/akciok",
    meta: on_45saleyeJldrXgxCMeta || {},
    component: () => import("/codebuild/output/src2610789722/src/storefront/pages/on-sale.vue").then(m => m.default || m)
  },
  {
    name: "on-sale___en",
    path: "/on-sale",
    meta: on_45saleyeJldrXgxCMeta || {},
    component: () => import("/codebuild/output/src2610789722/src/storefront/pages/on-sale.vue").then(m => m.default || m)
  },
  {
    name: "other-language-slug___hu",
    path: "/hu/egyeb-idegen-nyelvu-konyv/:slug()",
    meta: _91slug_93z9xHftKtWqMeta || {},
    component: () => import("/codebuild/output/src2610789722/src/storefront/pages/other-language/[slug].vue").then(m => m.default || m)
  },
  {
    name: "other-language-slug___en",
    path: "/other-language/:slug()",
    meta: _91slug_93z9xHftKtWqMeta || {},
    component: () => import("/codebuild/output/src2610789722/src/storefront/pages/other-language/[slug].vue").then(m => m.default || m)
  },
  {
    name: "other-languages-category-subcategory___hu",
    path: "/hu/egyeb-idegen-nyelvu-konyvek/:categorysubcategory?",
    meta: indexcORgAT27V7Meta || {},
    component: () => import("/codebuild/output/src2610789722/src/storefront/pages/other-languages/[category]/[subcategory]/index.vue").then(m => m.default || m)
  },
  {
    name: "other-languages-category-subcategory___en",
    path: "/other-languages/:category()/:subcategory()",
    meta: indexcORgAT27V7Meta || {},
    component: () => import("/codebuild/output/src2610789722/src/storefront/pages/other-languages/[category]/[subcategory]/index.vue").then(m => m.default || m)
  },
  {
    name: "other-languages-category___hu",
    path: "/hu/egyeb-idegen-nyelvu-konyvek/:category()",
    meta: indexZuChVBLVEQMeta || {},
    component: () => import("/codebuild/output/src2610789722/src/storefront/pages/other-languages/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: "other-languages-category___en",
    path: "/other-languages/:category()",
    meta: indexZuChVBLVEQMeta || {},
    component: () => import("/codebuild/output/src2610789722/src/storefront/pages/other-languages/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: "other-languages___hu",
    path: "/hu/egyeb-idegen-nyelvu-konyvek",
    meta: indexcxeEatay8GMeta || {},
    component: () => import("/codebuild/output/src2610789722/src/storefront/pages/other-languages/index.vue").then(m => m.default || m)
  },
  {
    name: "other-languages___en",
    path: "/other-languages",
    meta: indexcxeEatay8GMeta || {},
    component: () => import("/codebuild/output/src2610789722/src/storefront/pages/other-languages/index.vue").then(m => m.default || m)
  },
  {
    name: "payment-result___hu",
    path: "/hu/barion-eredmeny",
    meta: payment_45resultveWdwLTaScMeta || {},
    component: () => import("/codebuild/output/src2610789722/src/storefront/pages/payment-result.vue").then(m => m.default || m)
  },
  {
    name: "payment-result___en",
    path: "/barion-result",
    meta: payment_45resultveWdwLTaScMeta || {},
    component: () => import("/codebuild/output/src2610789722/src/storefront/pages/payment-result.vue").then(m => m.default || m)
  },
  {
    name: "paypal-start___hu",
    path: "/hu/paypal-fizetes",
    meta: paypal_45startS4Es2cSt6BMeta || {},
    component: () => import("/codebuild/output/src2610789722/src/storefront/pages/paypal-start.vue").then(m => m.default || m)
  },
  {
    name: "paypal-start___en",
    path: "/paypal-start",
    meta: paypal_45startS4Es2cSt6BMeta || {},
    component: () => import("/codebuild/output/src2610789722/src/storefront/pages/paypal-start.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy___hu",
    path: "/hu/adatkezelesi-tajekoztato",
    component: () => import("/codebuild/output/src2610789722/src/storefront/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy___en",
    path: "/privacy-policy",
    component: () => import("/codebuild/output/src2610789722/src/storefront/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "products-category-subcategory___hu",
    path: "/hu/product/:category?/:subcategory?",
    meta: _91_91subcategory_93_93tN2JGx1hgJMeta || {},
    component: () => import("/codebuild/output/src2610789722/src/storefront/pages/products/[[category]]/[[subcategory]].vue").then(m => m.default || m)
  },
  {
    name: "products-category-subcategory___en",
    path: "/products/:category?/:subcategory?",
    meta: _91_91subcategory_93_93tN2JGx1hgJMeta || {},
    component: () => import("/codebuild/output/src2610789722/src/storefront/pages/products/[[category]]/[[subcategory]].vue").then(m => m.default || m)
  },
  {
    name: "purchase-and-shipping-conditions___hu",
    path: "/hu/vasarlasi-es-szallitasi-feltetelek",
    component: () => import("/codebuild/output/src2610789722/src/storefront/pages/purchase-and-shipping-conditions.vue").then(m => m.default || m)
  },
  {
    name: "purchase-and-shipping-conditions___en",
    path: "/purchase-and-shipping-conditions",
    component: () => import("/codebuild/output/src2610789722/src/storefront/pages/purchase-and-shipping-conditions.vue").then(m => m.default || m)
  },
  {
    name: "search___hu",
    path: "/hu/kereses",
    meta: searchgpff56xB8HMeta || {},
    component: () => import("/codebuild/output/src2610789722/src/storefront/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___en",
    path: "/search",
    meta: searchgpff56xB8HMeta || {},
    component: () => import("/codebuild/output/src2610789722/src/storefront/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "staff-recommendations___hu",
    path: "/hu/mi-ajanljuk",
    meta: staff_45recommendationsFd2pBindM0Meta || {},
    component: () => import("/codebuild/output/src2610789722/src/storefront/pages/staff-recommendations.vue").then(m => m.default || m)
  },
  {
    name: "staff-recommendations___en",
    path: "/staff-recommendations",
    meta: staff_45recommendationsFd2pBindM0Meta || {},
    component: () => import("/codebuild/output/src2610789722/src/storefront/pages/staff-recommendations.vue").then(m => m.default || m)
  },
  {
    name: "stationary-and-gift-slug___hu",
    path: "/hu/notesz-es-ajandektargy/:slug()",
    meta: _91slug_938BQHjcvRCIMeta || {},
    component: () => import("/codebuild/output/src2610789722/src/storefront/pages/stationary-and-gift/[slug].vue").then(m => m.default || m)
  },
  {
    name: "stationary-and-gift-slug___en",
    path: "/stationary-and-gift/:slug()",
    meta: _91slug_938BQHjcvRCIMeta || {},
    component: () => import("/codebuild/output/src2610789722/src/storefront/pages/stationary-and-gift/[slug].vue").then(m => m.default || m)
  },
  {
    name: "stationary-and-gifts-category-subcategory___hu",
    path: "/hu/noteszek-es-ajandektargyak/:categorysubcategory?",
    meta: index8AwBh7DW3EMeta || {},
    component: () => import("/codebuild/output/src2610789722/src/storefront/pages/stationary-and-gifts/[category]/[subcategory]/index.vue").then(m => m.default || m)
  },
  {
    name: "stationary-and-gifts-category-subcategory___en",
    path: "/stationary-and-gifts/:category()/:subcategory()",
    meta: index8AwBh7DW3EMeta || {},
    component: () => import("/codebuild/output/src2610789722/src/storefront/pages/stationary-and-gifts/[category]/[subcategory]/index.vue").then(m => m.default || m)
  },
  {
    name: "stationary-and-gifts-category___hu",
    path: "/hu/noteszek-es-ajandektargyak/:category()",
    meta: indexmioAa1aoWAMeta || {},
    component: () => import("/codebuild/output/src2610789722/src/storefront/pages/stationary-and-gifts/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: "stationary-and-gifts-category___en",
    path: "/stationary-and-gifts/:category()",
    meta: indexmioAa1aoWAMeta || {},
    component: () => import("/codebuild/output/src2610789722/src/storefront/pages/stationary-and-gifts/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: "stationary-and-gifts___hu",
    path: "/hu/noteszek-es-ajandektargyak",
    meta: indexLvq5jWGXxEMeta || {},
    component: () => import("/codebuild/output/src2610789722/src/storefront/pages/stationary-and-gifts/index.vue").then(m => m.default || m)
  },
  {
    name: "stationary-and-gifts___en",
    path: "/stationary-and-gifts",
    meta: indexLvq5jWGXxEMeta || {},
    component: () => import("/codebuild/output/src2610789722/src/storefront/pages/stationary-and-gifts/index.vue").then(m => m.default || m)
  },
  {
    name: "terms-and-conditions___hu",
    path: "/hu/altalanos-szerzodesi-feltetelek",
    component: () => import("/codebuild/output/src2610789722/src/storefront/pages/terms-and-conditions.vue").then(m => m.default || m)
  },
  {
    name: "terms-and-conditions___en",
    path: "/terms-and-conditions",
    component: () => import("/codebuild/output/src2610789722/src/storefront/pages/terms-and-conditions.vue").then(m => m.default || m)
  },
  {
    name: "upcoming-releases___hu",
    path: "/hu/varhato-megjelenesek",
    meta: upcoming_45releaseskTRZOFZjWCMeta || {},
    component: () => import("/codebuild/output/src2610789722/src/storefront/pages/upcoming-releases.vue").then(m => m.default || m)
  },
  {
    name: "upcoming-releases___en",
    path: "/upcoming-releases",
    meta: upcoming_45releaseskTRZOFZjWCMeta || {},
    component: () => import("/codebuild/output/src2610789722/src/storefront/pages/upcoming-releases.vue").then(m => m.default || m)
  }
]